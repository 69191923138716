<template>
  <div
    class="page flex-col"
    id="page"
    @click="collapseMenu()"
    @touchmove="collapseMenu()"
  >
    <div
      class="group1"
      :style="{ background: this.tabBar ? '#FFFFFF' : 'rgba(0, 0, 0, 0)' }"
    >
      <div class="group1_inner flex-row">
        <img
          class="pic1"
          :src="this.tabBar ? this.versa_logo : this.versa_logo1"
          alt=""
          @click="clickNum('home')"
          :title="!this.tabBar ? 'Cooclip' : ''"
        />
        <div
          class="box0 flex-row"
          :style="{ color: !this.tabBar ? '#FFFFFF' : '#000000' }"
        >
          <span
            class="txt1"
            :class="{ activeColor: this.currentMenuIndex == 1 }"
            @click="clickNum('product')"
            >产品</span
          >
          <span
            class="creation"
            :class="{ activeColor: this.currentMenuIndex == 2 }"
            @click="clickNum('creation')"
            >创作者</span
          >
          <span
            class="brain_science"
            :class="{ activeColor: this.currentMenuIndex == 3 }"
            @click="clickNum('brain_science')"
            >人工智能AI</span
          >
          <span
            class="big_event"
            :class="{ activeColor: this.currentMenuIndex == 4 }"
            @click="clickNum('big_event')"
            >大事件</span
          >
          <span
            class="join_us"
            :class="{ activeColor: this.currentMenuIndex == 5 }"
            @click="clickNum('join_us')"
            >加入我们</span
          >
        </div>
      </div>
    </div>
    <div
      class="group_1"
      :style="{
        transform: this.isOpenMenu ? 'translateX(100%)' : 'translateX(-100%)',
      }"
      @touchmove.stop="handle()"
    >
      <div class="delete">
        <img
          src="../assets/images/pc/delete.png"
          alt=""
          @click.stop="clickDelete()"
        />
      </div>
      <div class="menu_box">
        <div
          class="menu_item"
          v-for="(item, index) in this.menuList"
          :key="item.id"
        >
          <span
            :style="{
              color: this.currentMenuIndex == index ? '#FF3366' : '#2A2A2A',
            }"
            @click.stop="clickNum(item.id, index)"
            >{{ item.menu }}</span
          >
        </div>
      </div>
    </div>
    <img
      class="mobile_menu"
      :src="this.tabBar ? mobile_menu2 : mobile_menu"
      alt=""
      @click.stop="clickMenu()"
    />
    <div class="video_box" v-if="this.isPlay" @click="quit()">
      <iframe
        v-if="this.isPlayIframe"
        class="video_play"
        :src="this.currentIframeUrl"
        scrolling="no"
        border="0"
        frameborder="no"
        framespacing="0"
        allowfullscreen="true"
      >
      </iframe>
      <div v-if="!this.isPlayIframe" class="video_play" @click.stop="handle()">
        <!-- <span>{{this.currentPlayObj+this.currentVideoUrl}}</span> -->
        <video
          class="full_video"
          id="full_video"
          :controls="true"
          :controlsList="!this.systemType ? '' : nofullscreen"
          autoplay
          playsinline
          webkit-playsinline
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
        >
          <source
            type="video/mp4"
            :src="this.currentVideoUrl"
            webkit-playsinline
            playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            x5-video-orientation="portraint"
          />
        </video>
      </div>
    </div>
    <div
      class="main1 flex-col"
      id="home"
      :class="currentTime > 1637240400000 ? 'main1_present' : ''"
    >
      <div class="main_1 flex-col" v-if="currentTime <= 1637240400000">
        <div id="group2" class="group_present">
          <div class="coocli">
            <img
              class="coocli_img"
              src="../../src/assets/images/pc/presentation/cooclip@2x.png"
              alt=""
            />
          </div>
          <div class="coocli">
            <img
              class="party_img"
              src="../../src/assets/images/pc/presentation/party@2x.png"
              alt=""
            />
          </div>
          <div class="coocli">
            <img class="bugu_img" :src="bugu_img" alt="" />
          </div>
          <div class="coocli">
            <div class="order_num">
              <img
                class="order_botton_img"
                :src="order_botton_img"
                alt=""
                @touchstart="starAnimation()"
                @touchend="stopAnimation()"
                @mousedown="starAnimation()"
                @mouseup="stopAnimation()"
              />
              <img
                class="order_botton_gif"
                :src="
                  currentTime > 1637228400000 && currentTime < 1637240400000
                    ? require('../../src/assets/images/pc/presentation/button1@3x.gif')
                    : require('../../src/assets/images/pc/presentation/button@2x.gif')
                "
                alt=""
                @touchstart="starAnimation('1')"
                @touchend="stopAnimation('2')"
                @mousedown="starAnimation('3')"
                @mouseup="stopAnimation('4')"
              />

              <img
                class="gezi"
                src="../../src/assets/images/pc/presentation/gezi.png"
                alt=""
              />
              <div
                v-if="
                  !(currentTime > 1637228400000 && currentTime < 1637240400000)
                "
                class="order_num_text"
              >
                <!-- 已预约<span class="order_num">143654</span>人，直播当天<span class="send_cash">送现金</span> -->
              </div>
              <!-- <img
                v-if="
                  !(currentTime > 1637228400000 && currentTime < 1637240400000)
                "
                class="order_num_text"
                src="../../src/assets/images/pc/presentation/2@2x.png"
                alt=""
              /> -->
              <img
                v-else
                class="order_num_online"
                :src="
                  currentSreenWidth <= 848
                    ? require('../../src/assets/images/pc/presentation/online_h5.png')
                    : require('../../src/assets/images/pc/presentation/online_pc.png')
                "
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- <img
          id="group2"
          class="group2"
          src=""
          picSrc="http://static01.versa-ai.com/upload/e662803524ab/b127ba2b-217c-478b-804b-e96d9646bae6.gif"
          webppicSrc="https://static01.versa-ai.com/upload/1615cbebc6fe/0de90c22-7cb0-4b7b-97c9-8088114db3ba.webp"
          alt=""
        /> -->
        <!-- <img v-if="this.browserVersion>=14 || !this.browserVersion" class="group2" src="https://static01.versa-ai.com/upload/1615cbebc6fe/0de90c22-7cb0-4b7b-97c9-8088114db3ba.webp"> -->
        <!-- <img v-else-if="this.browserVersion<14" class="group2" src="http://static01.versa-ai.com/upload/e662803524ab/b127ba2b-217c-478b-804b-e96d9646bae6.gif"> -->
        <!-- <img class="group2" src="https://static01.versa-ai.com/upload/e233965577f1/5d1a867a-fd59-4e1b-94aa-1ff10ebaaa17.png"> -->
        <!-- <img class="group2" :src="this.current_animation"> -->
        <!-- <img class="pic2" src="../assets/images/pc/big_logo.png" alt=""> -->
        <!-- <img class="pic2" :src="this.current_animation" alt=""> -->
        <!-- </img> -->
        <!-- <div class="group3">
          <img
            v-show="this.isShowTrigle"
            class="pic3"
            src="../assets/images/pc/down_trigle.png"
            alt=""
          />
        </div> -->
      </div>
      <div
        class="main_1 flex-col present_video"
        v-if="currentTime > 1637240400000"
      >
        <div id="group2" class="group_present group_present_video">
          <div class="coocli">
            <img
              class="cool_party"
              :src="
                currentSreenWidth <= 848
                  ? require('../../src/assets/images/pc/presentation/coolparty_h5@2x.png')
                  : require('../../src/assets/images/pc/presentation/coolparty@2x.png')
              "
              alt=""
            />
          </div>
          <div v-if="currentSreenWidth <= 848" class="coocli">
            <img
              class="present_text"
              src="../../src/assets/images/pc/presentation/h5@2x.png"
              alt=""
            />
          </div>

          <div class="coocli">
            <div
              class="after_video"
              @click="
                (this.isPlayIframe = true),
                  play1({
                    iframeUrl:
                      '//player.bilibili.com/player.html?aid=591859901&bvid=BV1Fq4y1r7oj&cid=444597719&page=1&autoplay=true&loop=true&danmaku=1',
                  })
              "
            >
              <img
                class="after_gezi"
                :src="
                  currentSreenWidth <= 848
                    ? require('../../src/assets/images/pc/presentation/gezi_h5.png')
                    : require('../../src/assets/images/pc/presentation/gezi.png')
                "
                alt=""
              />
              <img
                class="after_video_bg"
                src="../../src/assets/images/pc/presentation/tv.png"
                alt=""
              />
              <img
                class="video_paly"
                :src="
                  currentSreenWidth <= 848
                    ? require('../../src/assets/images/pc/presentation/h5_10.png')
                    : require('../../src/assets/images/pc/presentation/pc_30.png')
                "
                alt=""
              />
              <img
                class="video_paly_btn"
                src="../../src/assets/images/pc/presentation/present_play@2x.png"
                alt=""
              />
              <img
                class="present_up"
                src="../../src/assets/images/pc/presentation/present_up.png"
                alt=""
                v-if="currentSreenWidth > 848"
              />
              <img
                class="present_down"
                src="../../src/assets/images/pc/presentation/present_down.png"
                alt=""
                v-if="currentSreenWidth > 848"
              />
            </div>
          </div>
        </div>
        <!-- <img
          id="group2"
          class="group2"
          src=""
          picSrc="http://static01.versa-ai.com/upload/e662803524ab/b127ba2b-217c-478b-804b-e96d9646bae6.gif"
          webppicSrc="https://static01.versa-ai.com/upload/1615cbebc6fe/0de90c22-7cb0-4b7b-97c9-8088114db3ba.webp"
          alt=""
        /> -->
        <!-- <img v-if="this.browserVersion>=14 || !this.browserVersion" class="group2" src="https://static01.versa-ai.com/upload/1615cbebc6fe/0de90c22-7cb0-4b7b-97c9-8088114db3ba.webp"> -->
        <!-- <img v-else-if="this.browserVersion<14" class="group2" src="http://static01.versa-ai.com/upload/e662803524ab/b127ba2b-217c-478b-804b-e96d9646bae6.gif"> -->
        <!-- <img class="group2" src="https://static01.versa-ai.com/upload/e233965577f1/5d1a867a-fd59-4e1b-94aa-1ff10ebaaa17.png"> -->
        <!-- <img class="group2" :src="this.current_animation"> -->
        <!-- <img class="pic2" src="../assets/images/pc/big_logo.png" alt=""> -->
        <!-- <img class="pic2" :src="this.current_animation" alt=""> -->
        <!-- </img> -->
        <!-- <div class="group3">
          <img
            v-show="this.isShowTrigle"
            class="pic3"
            src="../assets/images/pc/down_trigle.png"
            alt=""
          />
        </div> -->
      </div>
    </div>
    <div class="main2 flex-col" id="product">
      <img class="bg_1" src="../assets/images/pc/bg_2.png" alt="" />
      <div class="main_2 flex-col">
        <div class="product flex-col">
          <div class="group4 flex-col">
            <div class="wrap10">产品介绍</div>
            <div class="wrap11"></div>
          </div>
          <div class="group5 flex-row">
            <div class="wrap5 flex-col">
              <img
                class="pic4"
                src="../assets/images/pc/presentation/bugu_logo.png"
                alt="Cooclip"
              />
              <div class="word2 flex-col">
                <div class="info3" :class="{ info3_expand: this.isBuGuExpand }">
                  不咕剪辑 Cooclip
                  是一款面向所有视频创作者，极易于操作的视频编辑工具。通过人工智能进行视频抠像分轨是不咕剪辑Cooclip的核心功能，极大程度的降低了视频创作者的视频制作时间，同时Cooclip
                  APP内置丰富的贴纸、音频、经典“梗”素材等即可增加视频本身的玩法与乐趣。上线1个月即受到了B站UP主们的欢迎与喜爱。
                </div>
                <div class="txt4_box">
                  <a
                    class="txt4"
                    target="_blank"
                    href="https://activity.versa-ai.com/bugu"
                    >查看更多</a
                  >
                  <div class="txt4_expand" @click="expand('bugu_expand')">
                    <span v-if="this.isBuGuExpand" class="expand">展开</span>
                    <span v-else-if="!this.isBuGuExpand" class="expand"
                      >收起</span
                    >
                    <img
                      class="expand_img"
                      :src="this.isBuGuExpand ? down_expand : up_expand"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="box flex-row">
                <div
                  class="box1 flex-col"
                  :class="{ bugu_active: this.currentBuGuIndex == index }"
                  v-for="(item, index) in buguFeatures"
                  :key="item.title"
                  @click="mousemove('bugu', item, index)"
                >
                  <img
                    class="pic5"
                    :src="
                      this.currentBuGuIndex == index
                        ? item.active_icon
                        : item.icon
                    "
                    alt=""
                  />
                  <span class="txt19">{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div class="wrap6">
              <img
                v-if="this.isSupportBrowser && this.isShowBuGuVideo"
                class="pic6"
                :style="{
                  width:
                    this.currentBuGuIndex == 4
                      ? '10.42vw'
                      : this.systemType && this.systemType != 'weixin'
                      ? '66.57vw'
                      : '14.64vw',
                  borderRadius:
                    this.currentBuGuIndex == 4
                      ? ''
                      : this.systemType && this.systemType != 'weixin'
                      ? ''
                      : '1.5vw',
                }"
                :src="this.bugu_current_feature"
                alt=""
              />
              <img
                v-if="!this.isSupportBrowser && this.isShowBuGuVideo"
                class="pic6"
                :style="{
                  width:
                    this.currentBuGuIndex == 4
                      ? '10.42vw'
                      : this.systemType && this.systemType != 'weixin'
                      ? '66.57vw'
                      : '14.64vw',
                  borderRadius:
                    this.currentBuGuIndex == 4
                      ? ''
                      : this.systemType && this.systemType != 'weixin'
                      ? ''
                      : '1.5vw',
                }"
                :src="this.bugu_current_feature_png"
                alt=""
              />
              <!-- <img v-if='!this.currentBuGuPlayObj && this.isSupportBrowser' class="pic6" :style="{width: this.currentBuGuIndex==4?'14.4vw':'14.64vw'}" :src="this.bugu_current_feature" alt="">
              <video v-if="this.currentBuGuPlayObj && this.isShowBuGuVideo && this.isSupportBrowser" class="pic6" :id="this.currentBuGuPlayObj" :controls="false" autoplay :loop='true' muted playsinline webkit-playsinline>
                 <source
                  type="video/mp4"
                  :src="this.bugu_current_video"
                  webkit-playsinline
                  playsinline
                />
              </video> -->
              <img
                class="bugu"
                src="https://static01.versa-ai.com/upload/65a6d12ca31b/b0e9f845-1372-4a06-8701-2b081d6836e0.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main10 flex-col">
      <img class="bg_2" src="../assets/images/pc/bg_3.png" alt="" />
      <div class="main_10 flex-col">
        <div class="group6 flex-row">
          <div class="wrap_6">
            <img
              v-if="this.isSupportBrowser && this.isShowWanTuVideo"
              class="pic6"
              :style="{
                width:
                  this.currentWanTuIndex == 4
                    ? '10.42vw'
                    : this.systemType && this.systemType != 'weixin'
                    ? '66.57vw'
                    : '14.64vw',
                borderRadius:
                  this.currentWanTuIndex == 4
                    ? ''
                    : this.systemType && this.systemType != 'weixin'
                    ? ''
                    : '1.5vw',
              }"
              :src="this.wantu_current_feature"
              alt=""
            />
            <img
              v-if="!this.isSupportBrowser && this.isShowWanTuVideo"
              class="pic6"
              :style="{
                width:
                  this.currentWanTuIndex == 4
                    ? '10.42vw'
                    : this.systemType && this.systemType != 'weixin'
                    ? '66.57vw'
                    : '14.64vw',
                borderRadius:
                  this.currentWanTuIndex == 4
                    ? ''
                    : this.systemType && this.systemType != 'weixin'
                    ? ''
                    : '1.5vw',
              }"
              :src="this.wantu_current_feature_png"
              alt=""
            />
            <!-- <img v-if='!this.currentWanTuPlayObj && this.isSupportBrowser' class="pic6" :style="{width: this.currentWanTuIndex==4?'14.4vw':'14.64vw'}" :src="this.wantu_current_feature" alt="">
            <video v-if='this.currentWanTuPlayObj && this.isShowWanTuVideo && this.isSupportBrowser' class="pic6" :id="this.currentWanTuPlayObj"  :controls="false" autoplay :loop='true' muted webkit-playsinline playsinline >
              <source
                type="video/mp4"
                :src="this.wantu_current_video"
                webkit-playsinline
                playsinline
              />
            </video> -->
            <img
              class="wantu"
              src="https://static01.versa-ai.com/upload/d78432a5f526/f814e323-ec43-40da-bd94-d5b61229c74a.png"
              alt=""
            />
          </div>
          <div class="wrap_5 flex-col">
            <img
              class="pic_4"
              src="../assets/images/pc/wnatu_logo.png"
              alt=""
            />
            <div class="word2 flex-col">
              <div
                class="info_3"
                :class="{ info_3_expand: this.isWanTuExpand }"
              >
                曾获App
                Store年度最佳应用，小红书抖音等个大平台博主力推的图片编辑神器！通过AI算法完成发丝级抠图，瞬间P掉路人、艺术滤镜等特色功能，帮助自媒体创作者随时随地的快速创作出更有趣的内容。还有更多快速功能换背景、生成证件照、节日贺卡、头像及海报等一键完成创作。
              </div>
              <div class="txt4_box">
                <a
                  class="txt_4"
                  target="_blank"
                  href="https://www.versa-ai.com/makaron/"
                  >查看更多</a
                >
                <div class="txt4_expand" @click="expand('wantu_expand')">
                  <span v-if="this.isWanTuExpand" class="expand">展开</span>
                  <span v-else-if="!this.isWanTuExpand" class="expand"
                    >收起</span
                  >
                  <img
                    class="expand_img"
                    :src="this.isWanTuExpand ? down_expand : up_expand"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="box flex-row">
              <div
                class="box1 flex-col"
                :class="{ wantu_active: this.currentWanTuIndex == index }"
                v-for="(item, index) in wantuFeatures"
                :key="item.title"
                @click="mousemove('wantu', item, index)"
              >
                <img
                  class="pic5"
                  :src="
                    this.currentWanTuIndex == index
                      ? item.active_icon
                      : item.icon
                  "
                  alt=""
                />
                <span class="txt19">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main3 flex-col" id="creation">
      <div class="main_3 flex-col">
        <div class="group7 flex-col">
          <div class="wrap12">创作者</div>
          <div class="wrap13"></div>
        </div>
        <div class="group8 flex-col">
          <div class="wrap14" :class="{ expandBox: this.isExpand }">
            在Versa的赋能下大量优质的b站up主及设计师，极大的释放内容创作的活力，在b站及各社区用户面前展现自己的创造才华，同时收获人气和奖金奖励（创作者激励计划）<br /><br />
            Versa为脑洞大开的创作者们提供了便捷有趣，充满激励和创作活力的工具——不咕剪辑及马卡龙玩图。我们一直积极赋能创作者为他们提供更好的创作工具，降低创作门槛，解放内容生产力，逐步深化优质内容生态建设，打造更丰富的创作生态圈。
            <!-- {{ this.userAgent }} -->
          </div>
          <div class="wrap15" @click="expand('creater')">
            <span v-if="this.isExpand" class="expand">展开</span>
            <span v-else-if="!this.isExpand" class="expand">收起</span>
            <img
              class="expand_img"
              :src="this.isExpand ? down_expand : up_expand"
              alt=""
            />
          </div>
        </div>
        <div class="group9 flex-col">
          <img
            v-if="this.isNext"
            class="next"
            src="../assets/images/pc/next.png"
            alt=""
            @click="this.nextClick()"
          />
          <img
            v-else-if="!this.isNext"
            class="last"
            src="../assets/images/pc/last.png"
            alt=""
            @click="this.lastClick()"
          />
          <div class="wrap16 flex-row" id="wrap16">
            <div class="wrap16left flex-row">
              <img
                class="pic7"
                src="../assets/images/pc/presentation/creator_bugu_logo.png"
                alt=""
              />
              <span class="txt25">名人堂</span>
            </div>
            <img class="pic8" src="../assets/images/pc/bugu_ip.png" alt="" />
          </div>
          <div class="box2 flex-row" id="box2_scroll">
            <div
              class="box3 flex-col"
              v-for="item in this.currentFames"
              :key="item.title"
            >
              <div class="box4" @click="play1(item, 'wrap16')">
                <!-- <video class="box_4" :id="item.video" :src="item.videoUrl" :controls="false" :poster="item.poster">
                </video> -->
                <img class="box_4" :src="item.poster" alt="" />
                <img
                  class="play1"
                  :src="
                    this.currentPlayObj == item.video
                      ? this.currentPlay == 'pause'
                        ? this.playImg.pause
                        : this.playImg.play
                      : this.playImg.pause
                  "
                  alt=""
                />
                <!-- <img v-show="this.currentPlayObj==item.video?(this.currentPlay=='pause'?false:true):false" class="full_screen1" :src="this.fullScreen" alt="" > -->
              </div>
              <!-- <img class="box4" src="../assets/images/pc/pic2_before.jpeg" alt=""> -->
              <div class="box5">
                <span class="txt_5">{{ item.title }}</span>
              </div>
              <div class="box6 flex-row">
                <div class="box_6 flex-row">
                  <div class="pic9_img">
                    <img class="pic9" :src="item.img" alt="" />
                    <img class="pic9_bilibili" :src="this.bilibili" alt="" />
                  </div>
                  <span class="txt21">{{ item.name }}</span>
                </div>
                <span class="txt22">{{ item.fans }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="group10 flex-col">
          <div class="wrap17 flex-row">
            <div class="wrap17left flex-row">
              <img
                class="pic10"
                src="../assets/images/pc/creator_wantu_logo.png"
                alt=""
              />
              <span class="txt26">创作营</span>
            </div>
            <img class="pic11" src="../assets/images/pc/wantu_ip.png" alt="" />
          </div>
          <div class="box7 flex-row">
            <div
              class="box8 flex-col"
              v-for="item in wantuFames"
              :key="item.dec"
            >
              <!-- <img class="pic12" :src="item.img" alt=""> -->
              <div class="objStyle2">
                <div class="objStyle_3">
                  <img class="objStyle3" :src="item.beforeImg" alt="" />
                  <div class="objStyle4">
                    <span class="objStyle5">使用前</span>
                  </div>
                </div>
                <div class="objStyle6">
                  <!-- <div class="objStyle_6"> -->
                  <img class="objStyle7" :src="item.afterImg" alt="" />
                  <div class="objStyle8"></div>
                  <div class="objStyle9">
                    <span class="objStyle5">使用后</span>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
              <div class="box10 flex-row">
                <div class="box10_left flex-row">
                  <img class="pic13" :src="item.logo" alt="" />
                  <span class="txt23">{{ item.name }}</span>
                </div>
                <div class="box10_right flex-row">
                  <img
                    class="heart"
                    :src="this.systemType ? this.like_2 : this.like"
                    alt=""
                  />
                  <span class="txt24">{{ item.fans }}</span>
                </div>
              </div>
              <div class="box11">
                <span class="box_11">{{ item.dec }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main4 flex-col" id="brain_science">
      <div class="main_4 flex-col">
        <div class="group11 flex-col">
          <div class="wrap18">脑科学 X 人工智能</div>
          <div class="wrap19"></div>
        </div>
        <div class="group12 flex-row">
          <span class="sign3"></span>
          <span class="txt5">我们发表过的论文</span>
          <span class="sign3"></span>
        </div>
        <div class="group_13">
          <!-- <div class="barrages-drop">
            <vue-baberrage
              :isShow="this.barrageIsShow"
              :barrageList="this.barrageList"
              :maxWordCount="this.maxWordCount"
              :throttleGap="this.throttleGap"
              :loop="this.barrageLoop"
              :boxHeight="this.boxHeight"
              :messageHeight="this.messageHeight"
            ></vue-baberrage>
          </div> -->
          <div class="versa_lab flex-col">
            <div class="versa_lab_img">
              <img class="mid_bilibili" :src="this.bilibili" alt="" />
              <img class="lab_img" src="../assets/images/pc/lab.png" alt="" />
            </div>
            <div class="lab_txt">Versa AI Lab</div>
          </div>
          <div class="group13" id="group13"></div>
        </div>
        <div class="group14 flex-row">
          <div class="box12 flex-col" id="box12">
            <div
              class="box_12"
              @click="
                play2(
                  AIlabObj[0].id,
                  AIlabObj[0].videoUrl,
                  AIlabObj[0].iframeUrl,
                  'box12'
                )
              "
            >
              <!-- <video
                class="pic14"
                id="video1"
                src="https://static01.versa-ai.com/upload/10912ca5585b/bb3ab829-7230-4ae9-997a-8b1fa3d51261.mp4"
                :controls="false"
                poster='https://static01.versa-ai.com/upload/69c75f674977/1ddbd563-7c36-4d72-a9b9-b6096d10e765.jpg'
                ></video> -->
              <img
                class="pic14"
                src="https://static01.versa-ai.com/upload/0b80997b453d/ee918a7f-f3a1-40bc-8c08-9c4a0bf904c9.png"
                alt=""
              />
              <img
                class="play2"
                :src="
                  this.currentPlayObj == 'video1'
                    ? this.currentPlay == 'pause'
                      ? this.playImg.pause
                      : this.playImg.play
                    : this.playImg.pause
                "
                alt=""
              />
              <img
                v-show="
                  this.currentPlayObj == 'video1'
                    ? this.currentPlay == 'pause'
                      ? false
                      : true
                    : false
                "
                class="full_screen"
                :src="this.fullScreen"
                alt=""
              />
            </div>
            <!-- <img class="pic14" src="../assets/images/pc/pic4_after.jpeg" alt=""> -->
            <div class="btxt6">
              <span class="btxt_6">摄影的另一种可能-3d场景重构</span>
            </div>
          </div>
          <div class="box13 flex-row">
            <div class="box14 flex-col">
              <div
                class="box_14"
                @click="
                  play2(
                    AIlabObj[1].id,
                    AIlabObj[1].videoUrl,
                    AIlabObj[1].iframeUrl,
                    'box12'
                  )
                "
              >
                <!-- <video
                  class="pic15"
                  id="video2"
                  src="https://static01.versa-ai.com/upload/1d1d541b1821/2d4064a1-b4fc-4860-be2b-f3818dd17e8b.mp4"
                  :controls="false"
                  poster='https://static01.versa-ai.com/upload/69c75f674977/1ddbd563-7c36-4d72-a9b9-b6096d10e765.jpg'
                  ></video> -->
                <img
                  class="pic15"
                  src="https://static01.versa-ai.com/upload/f46136ad3ecc/1afd1e05-1304-4a19-9ac6-0694588519d8.png"
                  alt=""
                />
                <img
                  class="play1"
                  :src="
                    this.currentPlayObj == 'video2'
                      ? this.currentPlay == 'pause'
                        ? this.playImg.pause
                        : this.playImg.play
                      : this.playImg.pause
                  "
                  alt=""
                />
                <img
                  v-show="
                    this.currentPlayObj == 'video2'
                      ? this.currentPlay == 'pause'
                        ? false
                        : true
                      : false
                  "
                  class="full_screen1"
                  :src="this.fullScreen"
                  alt=""
                />
              </div>
              <div class="txt6">
                <span class="txt_6">2021革命性人工智能算法</span>
              </div>
            </div>
            <div class="box14 flex-col">
              <div
                class="box_14"
                @click="
                  play2(
                    AIlabObj[4].id,
                    AIlabObj[4].videoUrl,
                    AIlabObj[4].iframeUrl,
                    'box12'
                  )
                "
              >
                <!-- <video
                  class="pic15"
                  id="video5"
                  src="https://static01.versa-ai.com/upload/039f6a5503dc/5b312a16-8061-4316-8714-fa409fda4d0a.mp4"
                  :controls="false"
                  poster='https://static01.versa-ai.com/upload/474657fd9d35/c05802ae-a8b2-4454-bae7-ca17fed0b900.jpg'
                  ></video> -->
                <img
                  class="pic15"
                  src="https://static01.versa-ai.com/upload/bd234b358427/b8b00066-7594-4272-9d6a-ba315f699f80.png"
                  alt=""
                />
                <img
                  class="play1"
                  :src="
                    this.currentPlayObj == 'video5'
                      ? this.currentPlay == 'pause'
                        ? this.playImg.pause
                        : this.playImg.play
                      : this.playImg.pause
                  "
                  alt=""
                />
                <img
                  v-show="
                    this.currentPlayObj == 'video5'
                      ? this.currentPlay == 'pause'
                        ? false
                        : true
                      : false
                  "
                  class="full_screen1"
                  :src="this.fullScreen"
                  alt=""
                />
              </div>
              <div class="txt6">
                <span class="txt_6">取代绿幕间的智能算法</span>
              </div>
            </div>
            <div class="box14 flex-col">
              <div
                class="box_14"
                @click="
                  play2(
                    AIlabObj[3].id,
                    AIlabObj[3].videoUrl,
                    AIlabObj[3].iframeUrl,
                    'box12'
                  )
                "
              >
                <!-- <video
                  class="pic15"
                  id="video4"
                  src="https://static01.versa-ai.com/upload/60d22cc445cf/00ad000d-d173-436a-83ba-e53a3a8d3e34.mp4"
                  :controls="false"
                  poster='https://static01.versa-ai.com/upload/2c65408913fe/7c3ddd3f-e40a-484d-a27d-503231c1bfe9.jpg'
                  ></video> -->
                <img
                  class="pic15"
                  src="https://static01.versa-ai.com/upload/5a32229f62c5/d6d742aa-e60e-4d91-9937-8b4f818895a4.png"
                  alt=""
                />
                <img
                  class="play1"
                  :src="
                    this.currentPlayObj == 'video4'
                      ? this.currentPlay == 'pause'
                        ? this.playImg.pause
                        : this.playImg.play
                      : this.playImg.pause
                  "
                  alt=""
                />
                <img
                  v-show="
                    this.currentPlayObj == 'video4'
                      ? this.currentPlay == 'pause'
                        ? false
                        : true
                      : false
                  "
                  class="full_screen1"
                  :src="this.fullScreen"
                  alt=""
                />
              </div>
              <div class="txt6">
                <span class="txt_6">如何把自己P成主角</span>
              </div>
            </div>
            <div class="box14 flex-col">
              <div
                class="box_14"
                @click="
                  play2(
                    AIlabObj[2].id,
                    AIlabObj[2].videoUrl,
                    AIlabObj[2].iframeUrl,
                    'box12'
                  )
                "
              >
                <!-- <video
                  class="pic15"
                  id="video3"
                  src="https://static01.versa-ai.com/upload/f18a0c37158a/604dbe40-9c33-4ac7-b968-3513a84f888c.mp4"
                  :controls="false"
                  poster='https://static01.versa-ai.com/upload/0437552a03de/28f03fbc-9009-4798-989b-58daa9e94154.jpg'
                  ></video> -->
                <img
                  class="pic15"
                  src="https://static01.versa-ai.com/upload/0bf253db5850/e67dc30c-9a19-473b-a3f7-4197e0c2c1b9.png"
                  alt=""
                />
                <img
                  class="play1"
                  :src="
                    this.currentPlayObj == 'video3'
                      ? this.currentPlay == 'pause'
                        ? this.playImg.pause
                        : this.playImg.play
                      : this.playImg.pause
                  "
                  alt=""
                />
                <img
                  v-show="
                    this.currentPlayObj == 'video3'
                      ? this.currentPlay == 'pause'
                        ? false
                        : true
                      : false
                  "
                  class="full_screen1"
                  :src="this.fullScreen"
                  alt=""
                />
              </div>
              <div class="txt6">
                <span class="txt_6">看脸时代打动另一半的AI技术！</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main5 flex-col">
      <div class="main_5 flex-col">
        <div class="group15 flex-col">
          <div class="wrap20">媒体报导</div>
          <div class="wrap21"></div>
        </div>
        <div class="group16 flex-row">
          <div
            class="scroll_view flex-row"
            id="scroll_view"
            @touchmove="scrollMove()"
          >
            <div
              class="media flex-col"
              v-for="item in medialist"
              :id="item.id"
              :key="item.id"
            >
              <img :class="item.logoClass" :src="item.img" alt="" />
              <div
                class="box15 flex-col"
                v-for="in_item in item.textlist"
                :key="in_item.content"
              >
                <a class="txt8" target="_blank" :href="in_item.link">{{
                  in_item.content
                }}</a>
                <span class="txt9">{{ in_item.date }}</span>
              </div>
            </div>
          </div>
          <div class="scroll_bottom">
            <div
              class="scroll_box"
              :style="{
                background:
                  this.currentBoxId == item.id ? '#FF3366' : '#DDDDDD',
              }"
              v-for="item in scrollBoxs"
              :key="item.id"
            ></div>
          </div>
        </div>
        <div class="group17 flex-col">
          <div
            class="box16 flex-row"
            :style="{
              borderBottom: this.systemType
                ? index == 7
                  ? ''
                  : '1px solid #F6F6F6'
                : '',
            }"
            v-for="(item, index) in medialist2"
            :key="item.img"
          >
            <div class="box16_left flex-row">
              <img :class="item.logoClass" :src="item.img" alt="" />
              <a class="txt10" target="_blank" :href="item.link">{{
                item.content
              }}</a>
            </div>
            <span class="txt11">{{ item.date }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main6 flex-col" id="big_event">
      <div class="main_6 flex-col">
        <div class="group18 flex-col">
          <div class="wrap22">企业发展史</div>
          <div class="wrap23"></div>
        </div>
        <div class="group19 flex-col">
          <div
            class="wrap24 flex-row"
            :style="{
              marginBottom:
                index % 2 == 0 && index !== 8
                  ? this.systemType
                    ? '4.53vw'
                    : '1vw'
                  : '',
            }"
            v-for="(item, index) in developHistory"
            :key="item.title"
          >
            <div class="box17">{{ item.year }}</div>
            <div class="box18 flex-col">
              <span class="sign1"></span>
              <div class="sign2"></div>
            </div>
            <div
              v-if="!this.systemType"
              class="box19"
              :style="{ width: index == 0 ? '5.5vw' : '2.8vw' }"
            >
              {{ item.month }}
            </div>
            <div
              v-else-if="this.systemType"
              class="box19"
              :style="{ width: index == 0 ? '16vw' : '9vw' }"
            >
              {{ item.month }}
            </div>
            <div class="box20 flex-col">
              <div class="txt12">{{ item.title }}</div>
              <div class="txt13">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main7 flex-col">
      <div class="main_7 flex-col">
        <div class="group20 flex-col">
          <div class="wrap25">创始人</div>
          <div class="wrap26"></div>
        </div>
        <div class="group21 flex-row">
          <div
            class="wrap27 flex-col"
            :id="item.id"
            v-for="item in founders"
            :key="item.nickName"
          >
            <div class="box21 flex-row" @click="this.perPageClick(item.link)">
              <div class="pic18_img">
                <img class="pic18" :src="item.logo" alt="" />
                <img
                  class="pic18_bilibili"
                  src="../assets/images/pc/big_bilibili.png"
                  alt=""
                />
              </div>
              <span class="txt14" :href="item.link">{{ item.nickName }}</span>
              <img class="pic19" :src="item.img" alt="" />
            </div>
            <div class="box22">{{ item.content }}</div>
            <div class="box_22" @click="play1(item)">
              <!-- <video class="box23" :id="item.video" :src="item.videoUrl" :controls="false" :poster="item.poster"></video> -->
              <img class="box23" :src="item.poster" alt="" />
              <img
                class="play2"
                :src="
                  this.currentPlayObj == item.video
                    ? this.currentPlay == 'pause'
                      ? this.playImg.pause
                      : this.playImg.play
                    : this.playImg.pause
                "
                alt=""
              />
              <img
                v-show="
                  this.currentPlayObj == item.video
                    ? this.currentPlay == 'pause'
                      ? false
                      : true
                    : false
                "
                class="full_screen2"
                :src="this.fullScreen"
                alt=""
              />
            </div>
            <!-- <video class="box23" :src="item.videoUrl" :controls="controls"></video> -->
            <!-- <img class="box23" src="../assets/images/pc/pic4_after.jpeg" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <div class="main8 flex-col" id="join_us">
      <div class="group22 flex-col">
        <div class="wrap28" @click="jumpCorporateCulture()">做一个例外</div>
        <div class="wrap29"></div>
      </div>
      <div class="group23 flex-row">
        <img class="pic20" src="../assets/images/pc/qr_code.png" alt="" />
        <div class="wrap30 flex-col">
          <div class="txt15">
            <!-- <div>价值观</div> -->
            <!-- <div class="txt16" @click="jumpCorporateCulture()" style="cursor: pointer">查看全部</div> -->
          </div>
          <div class="txt16">
            Versa的小伙伴们一直保持着对世界的好奇心，欢迎同样有趣的你加入，一起“做一个例外”。
          </div>
          <button @click="joinUs()"><span class="txt17">加入我们</span></button>
        </div>
      </div>
    </div>
    <div class="main9 flex-col">
      <div class="txt18">
        Copyright © 2017 Versa Inc. All rights reserved. • contact@versa-ai.com
      </div>
      <div class="txt18">
        上海懿天网络科技有限公司 •
        <a class="a_aliyun" href="https://beian.miit.gov.cn/" target="_blank"
          >沪ICP备17006537号-1
        </a>
      </div>
      <div class="txt18">
        <a
          class="a_aliyun"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.43.5af911d9mXr3L8&recordcode=31010502002674"
          target="_blank"
          rel="noopener noreferrer"
          >沪公网安备 31010502002674号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
// import Vue from 'vue'
// import { vueBaberrage, MESSAGE_TYPE } from 'vue-baberrage'
// Vue.use(vueBaberrage)

import versa_logo from "../assets/images/pc/versa_logo.png";
import versa_logo1 from "../assets/images/pc/versa_logo1.png";
import bugu_product from "../assets/images/pc/product_desc.png";
import bugu_product_active from "../assets/images/pc/bugu_product.png";
import bugu_kouxiang from "../assets/images/pc/bugu_kouxiang.png";
import bugu_kouxiang_active from "../assets/images/pc/bugu_kouxiang_1.png";
import bugu_sucai from "../assets/images/pc/bugu_sucai.png";
import bugu_sucai_active from "../assets/images/pc/bugu_sucai_1.png";
import bugu_texiao from "../assets/images/pc/texiao.png";
import bugu_texiao_active from "../assets/images/pc/bugu_texiao.png";
import bugu_down_load from "../assets/images/pc/down_load.png";
import bugu_down_load_active from "../assets/images/pc/bugu_down_load.png";
import bugu_feature1 from "../assets/images/pc/bugu_feature1.webp";
import bugu_feature1_png from "../assets/images/pc/bugu_feature1.png";
import bugu_feature2 from "../assets/images/pc/bugu_feature2.webp";
import bugu_feature2_png from "../assets/images/pc/bugu_feature2.png";
import bugu_feature3 from "../assets/images/pc/bugu_feature3.webp";
import bugu_feature3_png from "../assets/images/pc/bugu_feature3.png";
import bugu_feature4 from "../assets/images/pc/bugu_feature4.webp";
import bugu_feature4_png from "../assets/images/pc/bugu_feature4.png";
import bugu_code from "../assets/images/pc/bugu_code.gif";
import bilibili from "../assets/images/pc/mid_bilibili.png";
import wantu_product from "../assets/images/pc/product_desc.png";
import wantu_product_active from "../assets/images/pc/wantu_product.png";
import wantu_moban from "../assets/images/pc/wantu_moban.png";
import wantu_moban_active from "../assets/images/pc/wantu_moban_1.png";
import wantu_kouxiang from "../assets/images/pc/kouxiang.png";
import wantu_kouxiang_active from "../assets/images/pc/wantu_kouxiang.png";
import wantu_texiao from "../assets/images/pc/texiao.png";
import wantu_texiao_active from "../assets/images/pc/wantu_texiao.png";
import wantu_down_load from "../assets/images/pc/down_load.png";
import wantu_down_load_active from "../assets/images/pc/wantu_down_load.png";
import wantu_feature1 from "../assets/images/pc/wantu_feature1.webp";
import wantu_feature1_png from "../assets/images/pc/wantu_feature1.png";
import wantu_feature2 from "../assets/images/pc/wantu_feature3.webp";
import wantu_feature2_png from "../assets/images/pc/wantu_feature3.png";
import wantu_feature3 from "../assets/images/pc/wantu_feature2.webp";
import wantu_feature3_png from "../assets/images/pc/wantu_feature2.png";
import wantu_feature4 from "../assets/images/pc/wantu_feature4.webp";
import wantu_feature4_png from "../assets/images/pc/wantu_feature4.png";
import wantu_code from "../assets/images/pc/wantu_code.gif";
import ceo_logo from "../assets/images/pc/ceo.png";
import cto_logo from "../assets/images/pc/cto.png";
import big_play from "../assets/images/pc/big_play.png";
import pause from "../assets/images/pc/pause.png";
import expand from "../assets/images/pc/expand.png";
import up_expand from "../assets/images/pc/up_expand.png";
import like from "../assets/images/pc/like.png";
import like_2 from "../assets/images/pc/like_2.png";
import mobile_menu from "../assets/images/pc/mobile_menu.png";
import mobile_menu2 from "../assets/images/pc/mobile_menu2.png";
import full_screen from "../assets/images/pc/full_screen.png";
import pic1_before from "../assets/images/pc/pic1_before.jpeg";
import pic1_after from "../assets/images/pc/pic1_after.jpeg";
import pic2_before from "../assets/images/pc/pic2_before.jpeg";
import pic2_after from "../assets/images/pc/pic2_after.jpeg";
import pic3_before from "../assets/images/pc/pic3_before.jpeg";
import pic3_after from "../assets/images/pc/pic3_after.jpeg";
import pic4_before from "../assets/images/pc/pic4_before.jpeg";
import pic4_after from "../assets/images/pc/pic4_after.jpeg";

const buguFames = [
  {
    videoUrl:
      "https://static01.versa-ai.com/upload/310864bb74cb/ec83e4db-0e0b-4f00-99a4-f3570f79a0b5.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=373084908&bvid=BV1HZ4y1g7xk&cid=263285452&page=1",
    poster:
      "https://static01.versa-ai.com/upload/b009f39fafde/ccaa37a7-8ebc-4320-99e6-d9484ddfccc1.jpg",
    img: "https://static01.versa-ai.com/upload/d920887329c6/56095a2e-73e9-4dea-8d7d-d36e3fcb0aae.png",
    title: "时隔两年《鸽子》MV来了！ 4/9防道少女团 X Re-m!x",
    name: "Ilem",
    fans: "190.4w粉丝",
    video: "video6",
  },
  {
    videoUrl:
      "https://static01.versa-ai.com/upload/e908bfd81ef1/a65dbeff-ef7c-4658-a55c-f1176a16e7b6.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=585034332&bvid=BV1xz4y1o7X8&cid=248765258&page=1",
    poster:
      "https://static01.versa-ai.com/upload/76f9406ae706/1dc43de9-8232-4f40-af9f-eab2ccbc5127.jpg",
    img: "https://static01.versa-ai.com/upload/508801c36c32/175e0068-4771-4671-a773-ff770d92289b.png",
    title: "上海85后CEO，住什么样的房子？开什么样的车？",
    name: "阿福Thomas",
    fans: "146.6w粉丝",
    video: "video7",
  },
  {
    videoUrl:
      "https://static01.versa-ai.com/upload/e22c67b711fb/d9bcf471-a07d-4d59-b314-aa8b87c1c1da.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=970106606&bvid=BV1rp4y1k7ki&cid=248930880&page=1",
    poster:
      "https://static01.versa-ai.com/upload/10d5f5132494/708d4733-9573-49a8-8720-17f7bc40e209.jpg",
    img: "https://static01.versa-ai.com/upload/913fc4c41427/f90136a5-92c4-47a0-9bb4-5ed521ad5bee.jpg",
    title: "【猛男舞团】影 流 之 主",
    name: "猛男舞团IconX",
    fans: "184w粉丝",
    video: "video8",
  },
  {
    videoUrl:
      "https://static01.versa-ai.com/upload/80c13543ea55/59f502d8-0010-488d-9925-b95caa0192d9.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=246378368&bvid=BV1av411s7V2&cid=289361684&page=1",
    poster:
      "https://static01.versa-ai.com/upload/45306ba3c48d/e5dcca19-d84f-4727-9498-4b7495b75e04.png",
    img: "https://static01.versa-ai.com/upload/a86e455dc4e7/27e52a78-8154-4d6c-9dc5-aaff1b5ae902.png",
    title: "B站第一人！跳完社死！没人敢跳完的最骚健身操！鬼畜 乱入",
    name: "灵魂健身杨老师",
    fans: "91w粉丝",
    video: "video9",
  },
  {
    videoUrl:
      "https://static01.versa-ai.com/upload/62a7c5f1a887/e830805e-be63-4d91-98f5-63cb8cc35fdd.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=288330270&bvid=BV1Pf4y1v7HX&cid=271834155&page=1",
    poster:
      "https://static01.versa-ai.com/upload/460f5a64b359/dadb021a-1b99-4c19-a394-080e983dc1a9.jpg",
    img: "https://static01.versa-ai.com/upload/f4f3690eae65/6f929d0a-9315-452a-90d8-8dedbec11575.png",
    title: "我来了！全B站最奇怪の咒术回战！",
    name: "匠匠和小玉",
    fans: "15.4w粉丝",
    video: "video10",
  },
];

const AIlabObj = [
  {
    id: "video1",
    videoUrl:
      "https://static01.versa-ai.com/upload/10912ca5585b/bb3ab829-7230-4ae9-997a-8b1fa3d51261.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=931292638&bvid=BV1rM4y1u7Eu&cid=361034272&page=1",
  },
  {
    id: "video2",
    videoUrl:
      "https://static01.versa-ai.com/upload/1d1d541b1821/2d4064a1-b4fc-4860-be2b-f3818dd17e8b.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=331239169&bvid=BV1mA411H7M4&cid=281996112&page=1",
  },
  {
    id: "video3",
    videoUrl:
      "https://static01.versa-ai.com/upload/f18a0c37158a/604dbe40-9c33-4ac7-b968-3513a84f888c.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=713125542&bvid=BV1fX4y1M78W&cid=268005486&page=1",
  },
  {
    id: "video4",
    videoUrl:
      "https://static01.versa-ai.com/upload/60d22cc445cf/00ad000d-d173-436a-83ba-e53a3a8d3e34.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=755820889&bvid=BV1X64y1o7iF&cid=272471037&page=1",
  },
  {
    id: "video5",
    videoUrl:
      "https://static01.versa-ai.com/upload/039f6a5503dc/5b312a16-8061-4316-8714-fa409fda4d0a.mp4",
    iframeUrl:
      "//player.bilibili.com/player.html?aid=245554432&bvid=BV1Hv411b7dy&cid=265497726&page=1",
  },
];

const danmuPool = [
  "Hong Y, Zhao W, Zhang J, et al. F2GAN: Fusing-and-Filling GAN for Few-shot Image Generation[C]. ACM MM, 2020.",
  "Tu Y, Niu L, Zhao W, et al. Image Cropping with Composition and Saliency Aware Aesthetic Score Map[C]. AAAI, 2020: 12104-12111.",
  "Li Y, Zhang J, Zhao W, et al. Inductive Guided Filter: Real-Time Deep Matting with Weakly Annotated Masks on Mobile Devices[C]. 2020 IEEE International Conference on Multimedia and Expo (ICME). IEEE, 2020: 1-6.",
  "Zhang J, Zhao W, et al. GAIN: Gradient Augmented Inpainting Network for Irregular Holes[C]. ACM MM, 2019: 1870-1878.",
  "Zhang J, Zhao W, et al. Multi-attribute transfer via disentangled representation[C]. AAAI, 2019, 33: 9195-9202.",
  "Hong Y, Zhao W, Zhang J, et al. F2GAN: Fusing-and-Filling GAN for Few-shot Image Generation[C]. ACM MM, 2020.",
  "Tu Y, Niu L, Zhao W, et al. Image Cropping with Composition and Saliency Aware Aesthetic Score Map[C]. AAAI, 2020: 12104-12111.",
  "Li Y, Zhang J, Zhao W, et al. Inductive Guided Filter: Real-Time Deep Matting with Weakly Annotated Masks on Mobile Devices[C]. 2020 IEEE International Conference on Multimedia and Expo (ICME). IEEE, 2020: 1-6.",
  "Zhang J, Zhao W, et al. GAIN: Gradient Augmented Inpainting Network for Irregular Holes[C]. ACM MM, 2019: 1870-1878.",
  "Zhang J, Zhao W, et al. Multi-attribute transfer via disentangled representation[C]. AAAI, 2019, 33: 9195-9202.",
];
export default {
  data() {
    return {
      systemType: "",
      browserVersion: 0,
      userAgent: "",
      scrollIntoSite: "",
      isShowWanTuVideo: true,
      isShowBuGuVideo: true,
      isShowTrigle: true,
      isSupportBrowser: true,
      isSupportMobileBrowser: true,
      isPlayIframe: false,
      weixinHinkisShow: false,
      isSafari: false,
      isWebP: true,
      constants: {},
      screenWidth: 0,
      timer: null,
      barrage_timer: null,
      bugu_img: "",
      order_botton_img: "",
      // barrageIsShow: true,
      // messageHeight: 48,
      // boxHeight: 204,
      // barrageLoop: true,
      // maxWordCount: 100,
      // throttleGap: 5000,
      // barrageList: [],
      // barrageStyle: {
      //   backgroundColor: '#fff',
      //   color: '#000'
      // },
      MAX_DM_COUNT: 6,
      CHANNEL_COUNT: 3,
      domPool: [],
      danmuPool: danmuPool,
      hasPosition: [],
      pageYOffsetRatio: 0,
      scrollLeft: 0,
      videoId: "",
      currentBoxId: "box1",
      currentIframeUrl: "",
      currentVideoUrl: "",
      currentFames: buguFames,
      AIlabObj: AIlabObj,
      currentBuGuIndex: 0,
      currentWanTuIndex: 0,
      currentMenuIndex: -1,
      currentPlay: "pause",
      currentPlayObj: "",
      currentBuGuPlayObj: "video13",
      currentWanTuPlayObj: "video17",
      isOpenMenu: false,
      tabBar: false,
      isPlay: false,
      isExpand: true,
      isBuGuExpand: true,
      isWanTuExpand: true,
      isNext: true,
      versa_logo: versa_logo,
      versa_logo1: versa_logo1,
      mobile_menu: mobile_menu,
      mobile_menu2: mobile_menu2,
      bilibili: bilibili,
      like: like,
      like_2: like_2,
      playImg: {
        play: pause,
        pause: big_play,
      },
      down_expand: expand,
      up_expand: up_expand,
      fullScreen: full_screen,
      // current_animation: logo0,
      bugu_current_feature: bugu_feature1,
      bugu_current_feature_png: bugu_feature1_png,
      wantu_current_feature: wantu_feature1,
      wantu_current_feature_png: wantu_feature1_png,
      bugu_current_video:
        "https://static01.versa-ai.com/upload/354e5ab442e9/31fb2975-de00-4c6f-9afa-2eabbee575ef.mp4",
      wantu_current_video:
        "https://static01.versa-ai.com/upload/479839bf3647/4e6ffd54-a861-4d62-9157-6a515da3a814.mp4",
      buguFeatures: [
        {
          icon: bugu_product,
          active_icon: bugu_product_active,
          title: "产品介绍",
          feature_img: bugu_feature1,
          feature_img_png: bugu_feature1_png,
          feature_video:
            "https://static01.versa-ai.com/upload/354e5ab442e9/31fb2975-de00-4c6f-9afa-2eabbee575ef.mp4",
          id: "video13",
        },
        {
          icon: bugu_kouxiang,
          active_icon: bugu_kouxiang_active,
          title: "抠像",
          feature_img: bugu_feature2,
          feature_img_png: bugu_feature2_png,
          feature_video:
            "https://static01.versa-ai.com/upload/6b73bc4c9b92/22795da0-3aca-44f9-b911-3ff813846290.mp4",
          id: "video14",
        },
        {
          icon: bugu_sucai,
          active_icon: bugu_sucai_active,
          title: "素材库",
          feature_img: bugu_feature3,
          feature_img_png: bugu_feature3_png,
          feature_video:
            "https://static01.versa-ai.com/upload/061ee5fa1188/1e59c09a-a2ec-4fab-813d-bd2817cea740.mp4",
          id: "video15",
        },
        {
          icon: bugu_texiao,
          active_icon: bugu_texiao_active,
          title: "特效滤镜",
          feature_img: bugu_feature4,
          feature_img_png: bugu_feature4_png,
          feature_video:
            "https://static01.versa-ai.com/upload/4b87da6e2c1c/c2d03004-cc4e-4413-ae86-5758fe626a76.mp4",
          id: "video16",
        },
        {
          icon: bugu_down_load,
          active_icon: bugu_down_load_active,
          title: "立即下载",
          feature_img: bugu_code,
          feature_img_png: bugu_code,
        },
      ],
      wantuFeatures: [
        {
          icon: wantu_product,
          active_icon: wantu_product_active,
          title: "产品介绍",
          feature_img: wantu_feature1,
          feature_img_png: wantu_feature1_png,
          feature_video:
            "https://static01.versa-ai.com/upload/479839bf3647/4e6ffd54-a861-4d62-9157-6a515da3a814.mp4",
          id: "video17",
        },
        {
          icon: wantu_moban,
          active_icon: wantu_moban_active,
          title: "模版",
          feature_img: wantu_feature2,
          feature_img_png: wantu_feature2_png,
          feature_video:
            "https://static01.versa-ai.com/upload/76b2a2705654/5ee8f25e-c8b2-4780-8782-3b3bb224ebb5.mp4",
          id: "video18",
        },
        {
          icon: wantu_kouxiang,
          active_icon: wantu_kouxiang_active,
          title: "抠像",
          feature_img: wantu_feature3,
          feature_img_png: wantu_feature3_png,
          feature_video:
            "https://static01.versa-ai.com/upload/05ecbbe21742/d7360de0-983c-4f6b-b27a-76e962114c81.mp4",
          id: "video19",
        },
        {
          icon: wantu_texiao,
          active_icon: wantu_texiao_active,
          title: "添加滤镜",
          feature_img: wantu_feature4,
          feature_img_png: wantu_feature4_png,
          feature_video:
            "https://static01.versa-ai.com/upload/33f5737088ac/21a070cf-8dbf-4cd4-bca7-3eb7030bd0ea.mp4",
          id: "video20",
        },
        {
          icon: wantu_down_load,
          active_icon: wantu_down_load_active,
          title: "立即下载",
          feature_img: wantu_code,
          feature_img_png: wantu_code,
        },
      ],
      wantuFames: [
        {
          img: pic1_after,
          beforeImg: pic1_before,
          afterImg: pic1_after,
          logo: "https://static01.versa-ai.com/upload/55e1e6435947/6a32fc43-bfd7-4695-9062-c54b09347cb8.png",
          name: "曦子的小彭",
          fans: "9807",
          dec: "哇塞，马卡龙玩图真是一键抠图P图的神器～",
        },
        {
          img: pic2_after,
          beforeImg: pic2_before,
          afterImg: pic2_after,
          logo: "https://static01.versa-ai.com/upload/7dffad7a55b6/a6108b88-3be6-4684-8f17-6e9a92501490.png",
          name: "Mr.cctd",
          fans: "10224",
          dec: "制作的模板还能分享给好友，喊Ta一起玩～",
        },
        {
          img: pic3_after,
          beforeImg: pic3_before,
          afterImg: pic3_after,
          logo: "https://static01.versa-ai.com/upload/9f9f2b55a8bf/c8996e09-31c9-4761-b048-19b0ade8b0c0.png",
          name: "你过来转个圈",
          fans: "24276",
          dec: "海量模板、精美滤镜真是俘获了我的心",
        },
        {
          img: pic4_after,
          beforeImg: pic4_before,
          afterImg: pic4_after,
          logo: "https://static01.versa-ai.com/upload/d2a4431932eb/653a7624-dd65-4a43-828f-4b625fc1bc4b.png",
          name: "Cc",
          fans: "7803",
          dec: "草稿箱太贴心了，P了一半的图，等会儿还能接着做",
        },
      ],
      medialist: [
        {
          id: "box1",
          img: "https://static01.versa-ai.com/upload/726f275a3dbf/46f8814c-dbbe-407d-b4b0-063e1e7cea7d.png",
          textlist: [
            {
              content:
                "无足轻重的视频剪辑工具不经意间成为头部视频平台较量的“新战场”",
              link: "http://m.eeo.com.cn/2020/1103/429112.shtml",
              date: "2020.11",
            },
            // {content: '「好产品会发光」带你领略不一样的马卡龙', date: '2020.08'},
            // {content: '「好产品会发光」带你领略不一样的马卡龙', date: '2020.08'},
            // {content: '「好产品会发光」带你领略不一样的马卡龙', date: '2020.08'},
          ],
          logoClass: "pic16",
        },
        {
          id: "box2",
          img: "https://static01.versa-ai.com/upload/c6a429d44666/40f8fba9-c68c-4beb-aa42-83608ad0d160.png",
          textlist: [
            {
              content: "B站、抖音、快手“三国杀” 视频剪辑工具打响前哨战",
              link: "https://www.yicai.com/epaper/pc/202010/30/node_A08.html",
              date: "2020.10",
            },
            // {content: 'iOS、PC、Android 值得下载的 28 个 App', date: '2019.10'},
            // {content: '一周 App 派评 | 近期值得关注的 13 款应用', date: '2019.09'},
            // {content: '「马卡龙玩图」好玩在哪？', date: '2019.03'},
          ],
          logoClass: "pic16",
        },
        {
          id: "box3",
          img: "https://static01.versa-ai.com/upload/86c80804c216/9a9297d0-e34c-4b4e-a3e4-a7dd48139fce.png",
          textlist: [
            {
              content: "拿到B站投资，这家AI公司拿到短视频市场的入场券",
              link: "https://www.thepaper.cn/newsDetail_forward_9769896",
              date: "2020.10",
            },
            // {content: '「Versa」更名「马卡龙玩图」，想做移动端的"Photoshop + Instagram"', date: '2018.08'},
            // {content: '什么叫酷？做一款95后爱用 阿姨妈妈也爱用的P图神器 | 涌流2020', date: '2018.08'},
            // {content: '36氪首发 | 用AI为创作者赋能，「Versa」获红杉中国3000万人民…', date: '2017.11'},
          ],
          logoClass: "pic16",
        },
        {
          id: "box4",
          img: "https://static01.versa-ai.com/upload/1ea1c6d89095/081c555e-710f-42df-9aac-f7a5c4b78800.png",
          textlist: [
            {
              content: "B站“双线下注”视频剪辑，夯实UP主内容生态护城河",
              link: "http://www.21cbr.com/mobile/article/84196.html",
              date: "2020.10",
            },
            // {content: '什么叫酷？做一款95后爱用 阿姨妈妈也爱用的P图神器 | 涌流2020', date: '2020.08'},
            // {content: '什么叫酷？做一款95后爱用 阿姨妈妈也爱用的P图神器 | 涌流2020', date: '2020.08'},
            // {content: '什么叫酷？做一款95后爱用 阿姨妈妈也爱用的P图神器 | 涌流2020', date: '2020.08'},
          ],
          logoClass: "pic16",
        },
      ],
      medialist2: [
        {
          img: "https://static01.versa-ai.com/upload/b1fc7303d467/2a69f95a-9c60-4bf7-9acc-3fb688bf310c.png",
          content: "元宇宙很远，但生产力变革的「盛宴」却很近了",
          link: "https://mp.weixin.qq.com/s/yHyLWmKtAbZ8vD0ATznvAQ",
          date: "2021.11",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/80118bfd09f5/4918f2ca-7bad-4325-9274-01d06e8c57c6.png",
          content: "从AI到视频特效，不咕剪辑让更多人踏入元宇宙的创作世界",
          link: "https://36kr.com/p/1490297922414213?channel=wechat",
          date: "2021.11",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/c3a602e729f2/0b585482-caff-46e8-98dc-ea383ea368c1.png",
          content: "UP主首款桌面创作工具，不咕剪辑专业版公测开启",
          link: "http://news.imobile.com.cn/articles/2021/0926/215188.shtml",
          date: "2021.10",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/d74f3eb7d2ac/88ad922a-1334-4479-956c-c1517f10fbd6.png",
          content: "不咕剪辑—荣获2021红点奖 全球首个获奖的剪辑工具产品",
          link: "http://www.chinaz.com/2021/0911/1302955.shtml",
          date: "2021.09",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/6a9e6f5ff890/fafd5bdf-640b-466e-9d48-dfb6349bad91.png",
          content: "像玩游戏一样剪视频”，不咕剪辑想成为中国版Adobe",
          link: "https://36kr.com/p/1259380473851649",
          date: "2021.06",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/0a1fd7f836ed/ab408943-9216-44b1-9eb5-256dcbadf1d2.png",
          content: "手机视频怎样剪辑更省力，你还不知道不咕剪辑？",
          link: "http://software.it168.com/a2020/1214/6413/000006413496.shtml",
          date: "2021.01",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/5c5c5466a9d7/f5452522-bb77-4ce8-9eec-c80a54a1add0.png",
          content: "视频时代的最后一道墙正在倒下",
          link: "https://mp.weixin.qq.com/s/pRr0xDYpjwRHPkaEg6qiNg",
          date: "2020.11",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/9ef422ba9e29/8bd7a07e-d077-470a-99b8-2764fed70654.png",
          content: "Versa 获 B 站领投数千万美元 B 轮融资，推出“不咕剪辑”App",
          link: "https://cn.technode.com/post/2020-10-26/versa-b-bilibili/",
          date: "2020.10",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/30f32555f09e/81ebd710-f261-40d8-95c4-e77f19c21ad3.png",
          content: "短视频平台之争，谁来奇袭Z世代？",
          link: "https://www.sohu.com/a/428083506_114819",
          date: "2020.10",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/b39f9190c16d/ff2121c3-e846-4bad-b371-a13f47e9ff60.png",
          content: "手机也能视频抠图，如何让人原地“环游世界”？",
          link: "https://www.tmtpost.com/4108468.html",
          date: "2019.08",
          logoClass: "pic17",
        },
        {
          img: "https://static01.versa-ai.com/upload/e95f3e3a8559/6247c2d4-d0ba-4128-b946-cc42c5e5cd91.png",
          content: "Versa黑科技与荣耀9X同台发布",
          link: "http://tech.chinadaily.com.cn/a/201907/30/WS5d3f9e5aa3106bab40a03317.html",
          date: "2019.07",
          logoClass: "pic17",
        },
      ],
      developHistory: [
        { year: "2022", month: "敬请期待", title: "", content: "" },
        {
          year: "2021",
          month: "11月",
          title: "不咕剪辑专业版正式上线",
          content: "up主的第一款PC剪辑软件",
        },
        {
          year: "",
          month: "9月",
          title: "不咕剪辑荣获德国红点奖",
          content: "第一个获奖的移动剪辑工具",
        },
        {
          year: "2020",
          month: "12月",
          title: "B+轮融资",
          content: "由金浦资本跟投",
        },
        {
          year: "",
          month: "10月",
          title: "不咕剪辑上线",
          content: "全球领先视频抠像功能",
        },
        {
          year: "2019",
          month: "11月",
          title: "B轮融资",
          content: "BiliBili领投数千万美元",
        },
        {
          year: "",
          month: "02月",
          title: "获得中国创新创业大赛冠军",
          content: "马卡龙玩图获中国共青团微博力推",
        },
        {
          year: "2018",
          month: "11月",
          title: "完成A轮融资",
          content: "腾讯投资数千万美元",
        },
        {
          year: "",
          month: "07月",
          title: "马卡龙玩图app上线",
          content: "上线即当选当年App Store中国最佳应用",
        },
        {
          year: "2017",
          month: "11月",
          title: "完成pre-A轮融资",
          content: "红杉资本（中国）",
        },
        {
          year: "",
          month: "06月",
          title: "Versa AI 上线",
          content: "全球首款文创类AI产品诞生",
        },
        {
          year: "",
          month: "02月",
          title: "公司创立—完成天使轮融资",
          content: "真格基金",
        },
      ],
      founders: [
        {
          id: "ceo",
          logo: "https://static01.versa-ai.com/upload/ada4d930f91a/d9a9391e-5e1e-4a2a-8094-b086b059e2ac.png",
          link: "https://space.bilibili.com/698468105",
          img: ceo_logo,
          nickName: "天天蔡天懿",
          content:
            "蔡天懿，Versa创始人及CEO。他在回顾自己的创业历程时，觉得心态是最重要的一环。戒急用忍，是他在自己手机壳上时刻提醒自己的四个字。让我们一起来听听这样一个有趣的年轻人，是如何讲述自己的创业故事的。",
          iframeUrl:
            "//player.bilibili.com/player.html?aid=500748649&bvid=BV1XK411u7Z5&cid=268146685&page=1",
          videoUrl:
            "https://static01.versa-ai.com/upload/e542a9b24a4c/7d695d00-389b-4aa1-9329-620e04d0e59c.mp4",
          poster:
            "https://static01.versa-ai.com/upload/36f375de1ac1/036f6762-6d65-4e1f-a914-f1b408073669.png",
          video: "video11",
        },
        {
          id: "cto",
          logo: "https://static01.versa-ai.com/upload/a01135545bde/1d596ea0-d84f-4b07-8520-e60f8b345366.png",
          link: "https://space.bilibili.com/1784303784",
          img: cto_logo,
          nickName: "lao科学赵",
          content:
            "赵维杰，Versa创始人及CTO。上海交大脑科学与人工智能联合实验室主任、上海市科委专家、人工智能学科带头人、华为-versa联合创新实验室主任。Versa AI实验室致力于前沿的计算机视觉算法研究，用顶尖的技术和开创性的产品，降低创作门槛。",
          iframeUrl:
            "//player.bilibili.com/player.html?aid=713125542&bvid=BV1fX4y1M78W&cid=268005486&page=1",
          videoUrl:
            "https://static01.versa-ai.com/upload/f18a0c37158a/604dbe40-9c33-4ac7-b968-3513a84f888c.mp4",
          poster:
            "https://static01.versa-ai.com/upload/ac388b2bcc4b/cc7afba5-4983-4cb7-a410-cce5ef9d3ffe.png",
          video: "video12",
        },
      ],
      // animation: [ logo1,logo2,logo3,logo4,logo5,logo6,logo7,logo8,logo9,logo10,logo11,logo12,logo13,logo14,
      //              logo15,logo16,logo17,logo18,logo19,logo20,logo21,logo22,logo23,logo24,logo25,logo26,logo27,logo28,
      //              logo29,logo30,logo31,logo32,logo33,logo34,logo35,logo36,logo37,logo38,logo39,logo40,logo41,logo42,
      //              logo43,logo44,logo45,logo46,logo47,logo48,logo49,logo50,logo51,logo52,logo53,logo54,logo55,logo56,
      //              logo57,logo58 ],
      menuList: [
        { menu: "产品", id: "product" },
        { menu: "创作者", id: "creation" },
        { menu: "人工智能AI", id: "brain_science" },
        { menu: "大事件", id: "big_event" },
        { menu: "加入我们", id: "join_us" },
      ],
      videos: [
        "video1",
        "video2",
        "video3",
        "video4",
        "video5",
        "video6",
        "video7",
        "video8",
        "video9",
        "video10",
        "video11",
        "video12",
      ],
      scrollBoxs: [
        { id: "box1" },
        { id: "box2" },
        { id: "box3" },
        { id: "box4" },
      ],
      currentTime: new Date().getTime(), //  new Date('2021-11-18 18:00:00').getTime() =》 1637228400000   new Date('2021-11-18 21:00:00').getTime() =》 1637240400000
      currentSreenWidth: 0,
      // isZhibo: false,
      // isHuifang: false,
    };
  },
  mounted() {
    this.checkPic(this.checkWebp());
    //监听退出全屏事件
    // window.onresize = function (){
    //   console.log(window, 'window window window window')
    // }

    // document.getElementById('full_video').addEventListener('webkitbeginfullscreen', () => {
    //   console.log('webkitbeginfullscreen webkitbeginfullscreen webkitbeginfullscreen webkitbeginfullscreen')
    // })

    // document.getElementById('full_video').addEventListener('webkitendfullscreen', () => {
    //   console.log('webkitendfullscreen webkitendfullscreen webkitendfullscreen webkitendfullscreen')
    // })

    window.addEventListener(
      "onorientationchange" in window ? "orientationchange" : "resize",
      () => {
        if (window.orientation === 180 || window.orientation === 0) {
          document.getElementById(this.scrollIntoSite).scrollIntoView();
          // console.log('竖屏状态 竖屏状态 竖屏状态 竖屏状态')
        }
        // if (window.orientation === 90 || window.orientation === -90 ){
        //   alert(‘横屏状态！’);
        // }
      },
      false
    );

    document.getElementById("scroll_view").addEventListener("scroll", () => {
      // console.log(document.getElementById('scroll_view').scrollLeft, '000000000')
      this.scrollLeft = document.getElementById("scroll_view").scrollLeft;
      if (this.scrollLeft / this.screenWidth < 0.29) {
        this.currentBoxId = "box1";
      } else if (
        this.scrollLeft / this.screenWidth >= 0.29 &&
        this.scrollLeft / this.screenWidth < 0.83
      ) {
        this.currentBoxId = "box2";
      } else if (
        this.scrollLeft / this.screenWidth >= 0.83 &&
        this.scrollLeft / this.screenWidth < 1.07
      ) {
        this.currentBoxId = "box3";
      } else {
        this.currentBoxId = "box4";
      }
    });

    document.getElementById("box2_scroll").addEventListener("scroll", () => {
      // console.log(this.screenWidth,document.getElementById('box2_scroll').scrollLeft, '000000000')
      this.scrollLeft = document.getElementById("box2_scroll").scrollLeft;
      if (this.scrollLeft / this.screenWidth > 0.15) {
        this.isNext = false;
      } else {
        this.isNext = true;
      }
    });

    window.addEventListener("scroll", () => {
      // console.log(window.pageYOffset, '1111111111')
      this.pageYOffsetRatio = (window.pageYOffset / this.screenWidth).toFixed(
        2
      );
      if (this.systemType) {
        if (this.pageYOffsetRatio < 1.32) {
          this.currentMenuIndex = -1;
          this.tabBar = false;
        } else if (
          this.pageYOffsetRatio >= 1.32 &&
          this.pageYOffsetRatio < 6.501
        ) {
          this.currentMenuIndex = 0;
          this.tabBar = true;
        } else if (
          this.pageYOffsetRatio >= 6.501 &&
          this.pageYOffsetRatio < 10.67
        ) {
          this.currentMenuIndex = 1;
          this.tabBar = false;
        } else if (
          this.pageYOffsetRatio >= 10.67 &&
          this.pageYOffsetRatio < 16.245
        ) {
          this.currentMenuIndex = 2;
          this.tabBar = true;
        } else if (
          this.pageYOffsetRatio >= 16.245 &&
          this.pageYOffsetRatio < 18.69
        ) {
          this.currentMenuIndex = 3;
          this.tabBar = false;
        } else if (this.pageYOffsetRatio >= 18.69) {
          this.currentMenuIndex = 4;
          this.tabBar = true;
        }
      } else {
        if (this.pageYOffsetRatio < 0.01) {
          this.isShowTrigle = true;
        } else {
          this.isShowTrigle = false;
        }
        if (this.pageYOffsetRatio < 0.54) {
          this.currentMenuIndex = 0;
          this.tabBar = false;
        } else if (
          this.pageYOffsetRatio >= 0.54 &&
          this.pageYOffsetRatio < 1.5
        ) {
          this.currentMenuIndex = 1;
          this.tabBar = true;
        } else if (
          this.pageYOffsetRatio >= 1.5 &&
          this.pageYOffsetRatio < 2.319
        ) {
          this.currentMenuIndex = 2;
          this.tabBar = false;
        } else if (
          this.pageYOffsetRatio >= 2.319 &&
          this.pageYOffsetRatio < 3.56
        ) {
          this.currentMenuIndex = 3;
          this.tabBar = true;
        } else if (
          this.pageYOffsetRatio >= 3.56 &&
          this.pageYOffsetRatio < 4.288
        ) {
          this.currentMenuIndex = 4;
          this.tabBar = false;
        } else if (
          this.pageYOffsetRatio >= 4.288 &&
          this.pageYOffsetRatio < 4.4
        ) {
          this.tabBar = true;
        } else if (this.pageYOffsetRatio >= 4.4) {
          this.currentMenuIndex = 5;
          this.tabBar = true;
        }
      }
    });
    // this.addToList()
    this.init();
    // 每隔1ms从弹幕池里获取弹幕（如果有的话）并发射
    this.barrage_timer = setInterval(() => {
      let channel;
      if (this.danmuPool.length && (channel = this.getChannel()) != -1) {
        let dom = this.domPool[channel].shift();
        let danmu = this.danmuPool.shift();
        this.danmuPool.push(danmu);
        this.shootDanmu(dom, danmu, channel);
      }
    }, 1);
    console.log(
      "当前时间小于202111月18好晚上九点",
      this.currentTime,
      this.currentTime > 1637240400000
    );
    window.onresize = this.onResize;
    // this.videos.forEach(item => {
    //   let elevideo = document.getElementById(item);
    //   elevideo.addEventListener('play', function() {
    //     console.log("开始播放");
    //   }, false);
    // })

    // let ref = this
    // this.videos.forEach(item => {
    //   let elevideo = document.getElementById(item);
    //   elevideo.addEventListener('ended', function() { //结束
    //     ref.currentPlay='pause'
    //     console.log("播放结束");
    //   }, false);
    // })
  },
  beforeMount() {
    this.userAgent = navigator.userAgent;
    this.screenWidth = document.body.clientWidth;
    this.systemType = this.system();
    this.isSupportBrowser = this.getBrowserType();
    this.isSupportMobileBrowser = this.getMobileBrowserType();
    this.onResize();
  },
  unmounted() {
    // clearInterval(this.timer)
    clearInterval(this.barrage_timer);
  },
  watch: {
    currentBuGuPlayObj(val) {
      // console.log(val,'val val val val')
      if (val.indexOf("video") > -1) {
        this.$nextTick(() => {
          this.isShowBuGuVideo = true;
        });
      }
    },
    currentWanTuPlayObj(val) {
      if (val.indexOf("video") > -1) {
        this.$nextTick(() => {
          this.isShowWanTuVideo = true;
        });
      }
    },
  },
  methods: {
    starAnimation() {
      console.log(3456789);
      document.getElementsByClassName("order_botton_img")[0].style.transform =
        "scale(0.9, 0.9)";
      document.getElementsByClassName("order_botton_gif")[0].style.transform =
        "scale(0.9, 0.9)";
    },
    stopAnimation(type) {
      console.log(type);
      document.getElementsByClassName("order_botton_img")[0].style.transform =
        "scale(1, 1)";
      document.getElementsByClassName("order_botton_gif")[0].style.transform =
        "scale(1, 1)";
      let url = !(
        this.currentTime > 1637228400000 && this.currentTime < 1637240400000
      )
        ? "https://b23.tv/KoOj63"
        : "https://live.bilibili.com/23519061?spm_id_from=333.999.0.0";
      window.open(url, "_blank");
      // if (this.currentSreenWidth <= 848 && type === "4") {
      //   return false;
      // }
      // if (!this.isZhibo) {
      //   console.log(7);
      //   this.isZhibo = true;
      // } else {
      //   console.log(8);
      //   this.isHuifang = true;
      // }
      // console.log(99, this.isZhibo);
    },
    onResize() {
      this.currentSreenWidth = window.innerWidth;
      this.bugu_img =
        window.innerWidth <= 848
          ? require("../../src/assets/images/pc/presentation/buguH5@2x.png")
          : require("../../src/assets/images/pc/presentation/bugu@2x.png");
      this.order_botton_img =
        window.innerWidth <= 848
          ? require("../../src/assets/images/pc/presentation/buttonH5@2x.png")
          : require("../../src/assets/images/pc/presentation/button@2x.png");
    },
    collapseMenu() {
      this.isOpenMenu = false;
    },
    nextClick() {
      document.getElementById("box2_scroll").scrollLeft = 1200;
      this.isNext = false;
    },
    lastClick() {
      document.getElementById("box2_scroll").scrollLeft = 0;
      this.isNext = true;
    },
    perPageClick(link) {
      window.open(link);
    },
    clickNum(id, index) {
      this.isOpenMenu = false;
      this.currentMenuIndex = index || 0;
      document.querySelector("#" + id).scrollIntoView();
    },
    clickMenu() {
      this.isOpenMenu = true;
    },
    clickDelete() {
      this.isOpenMenu = false;
    },
    expand(type) {
      if (type == "creater") {
        if (this.isExpand) {
          this.isExpand = false;
        } else {
          this.isExpand = true;
        }
      } else if (type == "bugu_expand") {
        if (this.isBuGuExpand) {
          this.isBuGuExpand = false;
        } else {
          this.isBuGuExpand = true;
        }
      } else {
        if (this.isWanTuExpand) {
          this.isWanTuExpand = false;
        } else {
          this.isWanTuExpand = true;
        }
      }
    },
    system() {
      // 判断并返回系统
      var u = navigator.userAgent;
      var isWx = u.toLowerCase().indexOf("micromessenger") > -1; // wexin
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // android
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      var isIpad = u.indexOf("iPad") > -1;
      var system;
      if (isIpad) {
        system = "iPad";
      } else if (isAndroid) {
        system = "android";
      } else if (isIOS) {
        system = "ios";
      } else if (isWx) {
        system = "weixin";
      }
      return system;
    },
    scrollMove() {
      // console.log(document.getElementById('scroll_view').scrollLeft,'666666666666')
    },
    mousemove(type, item, index) {
      // console.log(this.bugu_current_video,'pppppppppppppp')
      if (type == "bugu") {
        if (index == 4) {
          if (this.systemType == "android") {
            // this.currentBuGuPlayObj = ''
            // this.bugu_current_feature = item.feature_img
            // window.open('https://activity.versa-ai.com/mixedQrcode')
            window.open(
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.versa.vanilla",
              "_self"
            );
          } else if (this.systemType == "ios") {
            // this.currentBuGuPlayObj = ''
            // this.bugu_current_feature = item.feature_img
            window.open(
              "https://apps.apple.com/cn/app/%E4%B8%8D%E5%92%95%E5%89%AA%E8%BE%91-%E6%97%A0%E5%B9%BF%E5%91%8A-%E8%BD%BB%E6%9D%BE%E5%89%AA/id1528288216",
              "_self"
            );
          } else {
            this.currentBuGuIndex = index;
            this.currentBuGuPlayObj = "";
            this.bugu_current_feature = item.feature_img;
            this.bugu_current_feature_png = item.feature_img_png;
          }
        } else {
          this.currentBuGuIndex = index;
          if (this.currentBuGuPlayObj != item.id) {
            this.isShowBuGuVideo = false;
          }
          this.currentBuGuPlayObj = item.id;
          this.bugu_current_feature = item.feature_img;
          this.bugu_current_feature_png = item.feature_img_png;
          this.bugu_current_video = item.feature_video;
          // console.log(this.bugu_current_video,'pppppppppppppp')
          // document.getElementById(this.currentBuGuPlayObj).play()
        }
      } else {
        if (index == 4) {
          if (this.systemType == "android") {
            // this.currentWanTuPlayObj = ''
            // this.wantu_current_feature = item.feature_img
            // var u = navigator.userAgent
            // var isWx = u.toLowerCase().indexOf('micromessenger') > -1 // wexin
            // if (isWx) {
            //   // alert('点击右上角【…】，点击此打开下载安卓APK')
            //   this.weixinHinkisShow = true
            // }else{
            // window.open('https://static01.versa-ai.com/upload/e4e131fe24d2/724a5602-2605-4259-ae96-603fc8c12b64.apk')
            window.open(
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.versa",
              "_self"
            );
            // }
          } else if (this.systemType == "ios") {
            // this.currentWanTuPlayObj = ''
            // this.wantu_current_feature = item.feature_img
            window.open("https://apps.apple.com/cn/app/id1214783848", "_self");
          } else {
            this.currentWanTuIndex = index;
            this.currentWanTuPlayObj = "";
            this.wantu_current_feature = item.feature_img;
            this.wantu_current_feature_png = item.feature_img_png;
          }
        } else {
          this.currentWanTuIndex = index;
          if (this.currentWanTuPlayObj != item.id) {
            this.isShowWanTuVideo = false;
          }
          this.currentWanTuPlayObj = item.id;
          this.wantu_current_feature = item.feature_img;
          this.wantu_current_feature_png = item.feature_img_png;
          this.wantu_current_video = item.feature_video;
          // document.getElementById(this.currentWanTuPlayObj).play()
        }
      }
    },
    quit() {
      // console.log(this.currentPlayObj,'1111111111')
      this.isPlay = false;
      this.isPlayIframe = false;
      // document.getElementById("full_video").pause();
    },
    joinUs() {
      window.open("https://www.lagou.com/gongsi/189579.html");
    },
    jumpCorporateCulture() {
      window.open(window.location.origin + "/corporateCulture.html");
    },
    handle() {},
    play(id) {
      this.isPlay = true;
      this.currentPlayObj = id;
      this.currentVideoUrl = document.getElementById(id).src;
    },
    play1(item, type) {
      this.scrollIntoSite = item.id || type;
      this.isPlay = true;
      this.currentPlayObj = item.video;
      this.currentVideoUrl = item.videoUrl;
      this.currentIframeUrl = item.iframeUrl;
      console.log(this.currentIframeUrl, "ppppppppppppp");
    },
    play2(id, videoUrl, iframeUrl, type) {
      this.scrollIntoSite = type;
      this.isPlay = true;
      this.currentPlayObj = id;
      this.currentVideoUrl = videoUrl;
      this.currentIframeUrl = iframeUrl;
      // console.log(this.scrollIntoSite,'ppppppppppppp')
    },
    init() {
      let wrapper = document.getElementById("group13");
      // 先new一些span 重复利用这些DOM
      for (let j = 0; j < this.CHANNEL_COUNT; j++) {
        let doms = [];
        for (let i = 0; i < this.MAX_DM_COUNT; i++) {
          // 要全部放进wrapper
          let dom = document.createElement("span");
          wrapper.appendChild(dom);
          // 初始化dom的位置 通过设置className
          dom.className = "right";
          // DOM的通道是固定的 所以设置好top就不需要再改变了
          dom.style.top = this.systemType ? j * 100 + "px" : j * 4 + "vw";
          // 放入改通道的DOM池
          doms.push(dom);
          // 鼠标浮动弹幕停止
          if (!this.isSafari && !this.systemType) {
            dom.addEventListener("mousemove", () => {
              dom.className = "left_stop";
            });
            dom.addEventListener("mouseleave", () => {
              dom.className = "left";
            });
          }
          // 每次到transition结束的时候 就是弹幕划出屏幕了 将DOM位置重置 再放回DOM池
          dom.addEventListener("transitionend", () => {
            dom.className = "right";
            dom.style.transform = null;
            this.domPool[j].push(dom);
          });
        }
        this.domPool.push(doms);
      }
      // hasPosition 标记每个通道目前是否有位置
      for (let i = 0; i < this.CHANNEL_COUNT; i++) {
        this.hasPosition[i] = true;
      }
    },
    /**
     * 获取一个可以发射弹幕的通道 没有则返回-1
     */
    getChannel() {
      for (let i = 0; i < this.CHANNEL_COUNT; i++) {
        if (this.hasPosition[i] && this.domPool[i].length) return i;
      }
      return -1;
    },
    /**
     * 根据DOM和弹幕信息 发射弹幕
     */
    shootDanmu(dom, text, channel) {
      // console.log('biu~ [' + text + ']');
      dom.innerText = text;
      // 如果为每个弹幕设置 transition 可以保证每个弹幕的速度相同 这里没有保证速度相同
      // dom.style.transition = `transform ${7 + dom.clientWidth / 100}s linear`;
      // dom.style.transform = `translateX(${-dom.clientWidth}px)`;
      dom.className = "left";
      // if(!this.isSafari && !this.systemType) {
      //   dom.addEventListener('mousemove', () => {
      //       dom.className = 'left_stop';
      //       });
      //   dom.addEventListener('mouseleave', () => {
      //       dom.className = 'left';
      //       });
      // }

      this.hasPosition[channel] = false;
      // 弹幕全部显示之后 才能开始下一条弹幕
      // 大概 dom.clientWidth * 10 的时间 该条弹幕就从右边全部划出到可见区域 再加1秒保证弹幕之间距离
      if (this.systemType) {
        setTimeout(() => {
          this.hasPosition[channel] = true;
        }, dom.clientWidth * 12 + 1000);
      } else {
        setTimeout(() => {
          this.hasPosition[channel] = true;
        }, dom.clientWidth * 6 + 1000);
      }
    },
    getBrowserType() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isSafari =
        userAgent.indexOf("Safari") > -1 &&
        userAgent.indexOf("Chrome") == -1 &&
        userAgent.indexOf("Edge") == -1 &&
        userAgent.indexOf("MQQBrowser") == -1 &&
        userAgent.indexOf("OPR") == -1; //Safari浏览器
      this.isSafari = isSafari ? true : false;
      // console.log(this.isSafari,'this.isSafari this.isSafari this.isSafari this.isSafari')
      if (isSafari) {
        this.browserVersion = userAgent
          .split("Version/")[1]
          .split(" ")[0]
          .replace(/\./g, "");
        // console.log(this.browserVersion,'this.browserVersion this.browserVersion this.browserVersion')
      }
      if (
        (this.isSafari && this.browserVersion != 1403) ||
        (this.systemType == "ios" &&
          userAgent.search("Quark") == -1 &&
          userAgent.search("MQQBrowser") == -1 &&
          userAgent.search("UCBrowser") == -1 &&
          userAgent.search("HeyTapBrowser") == -1)
      ) {
        return false;
      }
      if (
        userAgent.search("Quark") !== -1 ||
        userAgent.search("HeyTapBrowser") !== -1 ||
        userAgent.search("UCBrowser") !== -1 ||
        userAgent.search("MQQBrowser") !== -1 ||
        this.browserVersion == 1403
      ) {
        this.isPlayIframe = true;
        return false;
      } else {
        return true;
      }

      // if(
      //     userAgent.search('Mobile') !== -1 &&
      //     userAgent.search('HeyTapBrowser') == -1 &&
      //     userAgent.search('Quark') == -1 &&
      //     userAgent.search('MQQBrowser') == -1
      //     // userAgent.search('VivoBrowser') !== -1 ||
      //     // userAgent.search('Firefox') !== -1 ||
      //     // userAgent.search('MicroMessenger') !== -1 ||
      //     // userAgent.search('Quark') !== -1 ||
      //     // userAgent.search('SogouMobileBrowser') !== -1
      //     ) {
      //   return true
      // }if(
      //     userAgent.search('HeyTapBrowser') !== -1 ||
      //     (userAgent.search('MQQBrowser') !== -1&&this.systemType=='ios')
      //     ) {
      //   this.isPlayIframe = true
      //   return true
      // }if(this.browserVersion == 1403) {
      //   return false
      // }if(userAgent.search('Quark') !== -1) {
      //   this.isPlayIframe = true
      //   return false
      // }else {
      //   return true
      // }
    },
    getMobileBrowserType() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if (
        this.systemType == "ios" &&
        (userAgent.search("OPT") == -1 || userAgent.search("Safari") == -1)
        // userAgent.search('UCBrowser') == -1||
        // userAgent.search('HeyTapBrowser') == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkWebp() {
      try {
        return (
          document
            .createElement("canvas")
            .toDataURL("image/webp")
            .indexOf("data:image/webp") == 0
        );
      } catch (err) {
        return false;
      }
    },
    checkPic(isWebP) {
      this.isWebP = isWebP;
      var src = isWebP
        ? document.querySelector("#group2").getAttribute("webppicSrc")
        : document.querySelector("#group2").getAttribute("picSrc");
      document.querySelector("#group2").setAttribute("src", src);
      // console.log(document.querySelector("#group2").src,src,'src src src src')
    },
    checkFull() {
      var isFull =
        document.getElementById(this.currentPlayObj).fullscreenEnabled ||
        document.getElementById(this.currentPlayObj).webkitIsFullScreen ||
        document.getElementById(this.currentPlayObj).msFullscreenEnabled;
      //to fix : false || undefined == undefined
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
  },
};
</script>
<style src="../assets/styles/index.css" />
